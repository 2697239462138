import { baseUrl } from "./index";

const storageTokenKey = "token-store";

let token;

export async function init() {
  const storageToken = window.localStorage.getItem(storageTokenKey);
  if (storageToken) {
    token = JSON.parse(storageToken);
    const parsed = getToken();
    if (parsed.exp * 1000 > Date.now()) {
      return token;
    }
    token = null;
  }

  const { search, origin } = window.location;
  const params = new URLSearchParams(search);
  const code = params.get("code");
  if (!code) {
    window.location.href = `${baseUrl()}/user/login?redirect_uri=${origin}`;
    return;
  }

  const respone = await fetch(`${baseUrl()}/user/token`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      redirect_uri: origin,
      code,
    }),
  });

  token = await respone.json();
  window.localStorage.setItem(storageTokenKey, JSON.stringify(token));

  if (window.location.search) {
    window.history.pushState(null, null, window.location.pathname);
  }

  return token;
}

export function getToken() {
  return JSON.parse(atob(token.id_token.split(".")[1]));
}

export async function wrapedFetch(url, options) {
  if (!token) {
    await init();
  }

  const response = await fetch(url, {
    ...options,
    mode: "cors",
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${token.id_token}`,
    },
  });

  if (response.status === 401) {
    window.localStorage.removeItem(storageTokenKey);
    init();
  }

  return response;
}

export async function logout() {
  console.log("DSADSADASDAS")
  window.localStorage.removeItem(storageTokenKey);
  token = null;
  init();
  // window.location.href = `${baseUrl()}/user/logout`;
}
