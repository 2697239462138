import { wrapedFetch } from "./auth";
import { baseUrl } from "./index";

export async function getStrategy({ params }) {
  const url = `${baseUrl()}/strategy/${params.id}/log`;
  const response = await wrapedFetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data
}
