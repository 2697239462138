import { useLoaderData } from "react-router-dom";

export default function StrategyDetail() {
  const strategy = useLoaderData();
  if (!strategy?.strategy) {
    return (
      <header className="pb-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            Strategy loading
          </h1>
        </div>
      </header>
    );
  }
  return (
    <>
      <header className="pb-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            {strategy.strategy.name}
          </h1>
        </div>
      </header>

      <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
     
                <h2 className="pb-8 text-xl font-semibold text-gray-900">Execution log</h2>

                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="table-fixed min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Time
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Level
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-full"
                        >
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {strategy.items.map((log) => (
                        <tr key={log.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                            {new Date(log.timestamp).toLocaleString()}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${levelColor(log.level)}`}>
                              {levelName(log.level)}
                            </span>
                          </td>

                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-sm sm:pr-6 w-full">
                            {log.message}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function levelName(level) {
  switch (level) {
    case "INFO":
      return "info";
    case "WARNING":
      return "trading";
  }
}


function levelColor(level) {
    switch (level) {
      case "INFO":
        return "bg-blue-100 text-blue-800";
      case "WARNING":
        return "bg-green-100 text-green-800";
    }
  }