import React, { useState, Fragment, Suspense } from "react";
import { useLoaderData, useRevalidator, Await, Link } from "react-router-dom";
import { disconnectBroker } from "../api/brokers";

const tabs = [{ name: "Orders", href: "#", current: true }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BrokerDetail() {
  const { auth, orders } = useLoaderData();
  const revalidator = useRevalidator();

  return (
    <>
      <header className="pb-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <Suspense
            fallback={
              <h1 className="text-3xl font-bold tracking-tight text-white">
                Loading broker...
              </h1>
            }
          >
            <Await
              resolve={auth}
              errorElement={
                <h1 className="text-3xl font-bold tracking-tight text-white">
                  Error loading broker!
                </h1>
              }
            >
              {(broker) => (
                <>
                  <h1 className="text-3xl font-bold tracking-tight text-white">
                    {broker.broker.name}
                  </h1>

                  <h2 className="text-sm text-white">
                    {broker.name ? (
                      <>
                        <span>
                          Connected as {broker.name} ({broker.user_id})
                        </span>
                        <a
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            disconnectBroker(broker.auth_url).then(
                              revalidator.revalidate
                            );
                          }}
                          className="ml-2 text-slate-400 hover:underline"
                        >
                          Disconnect
                        </a>
                      </>
                    ) : (
                      <a
                        href={`${broker.auth_url}/login?next=${window.location.href}`}
                        className="text-white hover:underline"
                      >
                        Connect
                      </a>
                    )}
                  </h2>
                </>
              )}
            </Await>
          </Suspense>
        </div>
      </header>

      <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="border-b border-gray-200 pb-5 sm:pb-0 mb-8">
                  <div className="mt-3 sm:mt-4">
                    <div className="sm:hidden">
                      <label htmlFor="current-tab" className="sr-only">
                        Select a tab
                      </label>
                      <select
                        id="current-tab"
                        name="current-tab"
                        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        defaultValue={tabs.find((tab) => tab.current).name}
                      >
                        {tabs.map((tab) => (
                          <option key={tab.name}>{tab.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <nav className="-mb-px flex space-x-8">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            href={tab.href}
                            className={classNames(
                              tab.current
                                ? "border-indigo-500 text-indigo-600"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                              "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>

                <Suspense fallback={<p>Loading orders...</p>}>
                  <Await
                    resolve={orders}
                    errorElement={<p>Error loading orders!</p>}
                  >
                    {(ord) => (
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Time
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Type
                              </th>

                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Amount
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Strategy
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {ord.items.map((order) => (
                              <OrderRow order={order} />
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </Await>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function OrderRow({ order }) {
  const [detailOpened, setDetailOpened] = useState(false);
  console.log(detailOpened);
  return (
    <Fragment key={order.id}>
      <tr className="cursor-pointer" onClick={() => setDetailOpened(!detailOpened)}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
          {new Date(order.timestamp).toLocaleString()}
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-sm">
          <span
            className={`capitalize inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${statusColor(
              order.status
            )}`}
          >
            {order.status}
          </span>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {order.details.BuySell}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {order.details.Amount}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm capitalize">
          <Link
            className="hover:underline"
            to={`/strategies/${order.strategy}`}
          >
            {order.strategy}
          </Link>
        </td>
      </tr>
      { detailOpened && (<tr>
        <td colSpan={5} className="bg-gray-50">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 px-8">
              <dt className="text-sm font-medium text-gray-500">Internal ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {order.id}
              </dd>
            </div>
            {order.broker_id && (
              <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 px-8">
                <dt className="text-sm font-medium text-gray-500">Broker ID</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {order.broker_id}
                </dd>
              </div>
            )}
            {order.order && (
              <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 px-8">
                <dt className="text-sm font-medium text-gray-500">
                  Raw Request
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <pre>{JSON.stringify(order.order, null, 4)}</pre>
                </dd>
              </div>
            )}
            {order.details && (
              <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 px-8">
                <dt className="text-sm font-medium text-gray-500">
                  Raw Broker Details
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <pre>{JSON.stringify(order.details, null, 4)}</pre>
                </dd>
              </div>
            )}
          </dl>
        </td>
      </tr>
      )}
    </Fragment>
  );
}

function statusColor(level) {
  switch (level) {
    case "created":
      return "bg-blue-100 text-blue-800";
    case "open":
      return "bg-green-100 text-green-800";
    case "closed":
      return "bg-gray-100 text-gray-800";
  }
}
