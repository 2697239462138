import { logout } from "../api/auth";

export default function Brokers() {
    logout();
    return (
      <>
        <header className="pb-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-white">
              Signing out...
            </h1>
          </div>
        </header>
      </>
    );
  }
  