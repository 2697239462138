import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import App from './App';
import Brokers from './views/brokers';
import BrokerDetail from './views/broker_detail';
import Strategies from './views/strategies';
import StrategyDetail from './views/strategy_detail';
import Dashboard from './views/dashboard';
import Logout from './views/logout';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import { init, logout } from './api/auth';
import { brokerLoader } from './api/brokers';
import { getStrategy } from './api/strategies';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: init,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/brokers",
        element: <Brokers />,
      },
      {
        path: "/brokers/:id",
        element: <BrokerDetail />,
        loader: brokerLoader,
      },
      {
        path: "/strategies",
        element: <Strategies />,
      },
      {
        path: "/strategies/:id",
        element: <StrategyDetail />,
        loader: getStrategy,
      },
      {
        path: "/logout",
        element: <Logout />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
