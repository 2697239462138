export default function Brokers() {
  return (
    <>
      <header className="pb-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            Dashboard
          </h1>
        </div>
      </header>

      <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col">
            <h2 className="pb-8 text-xl font-semibold text-gray-900">
              Charts and stuff
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
