import { defer } from "react-router-dom";

import { wrapedFetch } from "./auth";
import { baseUrl } from "./index";

export async function brokerLoader({ params }) {
  return defer({
    auth: getBroker(params.id),
    orders: getBrokerOrders(params.id),
  })
}

async function getBroker(broker_id) {
  const url = `${baseUrl()}/broker/${broker_id}/auth`;
  return wrapedFetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json())
  .then((broker) => ({ ...broker, auth_url: url }));
}

async function getBrokerOrders(broker_id) {
  const url = `${baseUrl()}/broker/${broker_id}/order`;
  return wrapedFetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
}

export async function disconnectBroker(url) {
  const response = await wrapedFetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response.json();
}
